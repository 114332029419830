<template>
  <div class="row">
    <div class="col">
      <!-- <router-link :to="{ name: 'Home' }">Menu</router-link> -->
      <div class="card shadow mt-4">
        <div class="card-body">
          <div>Token Dinamico</div>
          <h1>{{ token }}</h1>
          <LifeTokenBar v-if="progress" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onBeforeMount, onBeforeUnmount } from "vue";

import API from "@/api";

import LifeTokenBar from "../../components/Token/LifeTokenBar.vue";

export default defineComponent({
  components: {
    LifeTokenBar,
  },
  setup() {
    const token = ref("");
    const progress = ref(false);

    const getToken = async () => {
      try {
        progress.value = false;
        const response = await API.get("/v1/users/dinamic-token");

        token.value = response.data;
        progress.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    // set interval
    const refreshToken = setInterval(() => {
      getToken();
    }, 60000);

    onBeforeMount(async () => {
      getToken();
      refreshToken;
    });

    onBeforeUnmount(() => {
      clearInterval(refreshToken);
    });

    return {
      token,
      progress,
    };
  },
});
</script>
