<template>
  <div>
    <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";

export default {
  setup() {
    const totalTime = 60; // Tiempo total en segundos
    const progress = ref(100); // Valor inicial de la barra de progreso

    let timer; // Variable para almacenar el temporizador

    // Función para actualizar el progreso
    const updateProgress = () => {
      progress.value = 100; // Reinicia el progreso
      let count = totalTime;

      timer = setInterval(() => {
        count--;
        progress.value = (count / totalTime) * 100; // Actualiza el progreso

        if (count <= 0) {
          clearInterval(timer); // Detiene el cronómetro cuando llega a cero
          updateProgress(); // Reinicia el cronómetro
        }
      }, 1000); // Actualiza cada segundo
    };

    // Iniciar el cronómetro al montar el componente
    onMounted(updateProgress);

    // Reiniciar el cronómetro cuando totalTime cambia
    watch(
      () => totalTime,
      () => {
        clearInterval(timer); // Detiene el cronómetro actual
        updateProgress(); // Reinicia el cronómetro
      }
    );

    return {
      progress,
    };
  },
};
</script>

<style>
.progress-bar {
  height: 2px;
  background-color: rgb(17, 170, 75);
  transition: width 1s;
}
</style>
